import React from 'react'
import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import Loading from './Loading'

const GameStats = (props) => {
    const [data, setData] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    let game = props.game

    console.log(game)

    useEffect(() => {
        fetch(`https://api.faynscloud.co.uk/data/stats`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authentication': 'Bearer ' + Cookies.get('token')
            },
            body: JSON.stringify({
                uuid: props.uuid,
                game: game
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setData(data.data)
            setIsLoaded(true)
        })

        return () => {
            setData()
            setIsLoaded(false)
        }
        
    }, [props.game])

    if(!isLoaded){
        return (
            <Loading />
        )
    }
    console.log(data)
    return (
        <div class='flex justify-center'>
			<div class='bg-white w-full shadow-md rounded px-5 py-2'>
                <div>
                    Total games: {data.totalGames || 0}
                </div>
                <div>
                    { data.mostRecent ? <p>Most recent score: {data.mostRecent.score}</p> : <p>No recent games</p> }
                </div>
            </div>
        </div>
    )
}

export default GameStats