import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState, useEffect, Redirect } from 'react'
import Navigation from './components/Navbar'
import './App.css';
import Footer from './components/Footer';
import ReactionTimer from './components/ReactionTimer';
import Excercises from './components/Excercises';
import Home from './components/Home';
import NumberMemory from './components/NumberMemory';
import SequenceMemory from './components/SequenceMemory';
import Login from './components/Login';
import Cookies from 'js-cookie';
import decode from 'jwt-decode'
import Register from './components/Register';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import Stats from './components/Stats';
import TypingSpeed from './components/TypingSpeed';
import { ChimpTest } from './components/ChimpTest';
import Memory from './components/Memory';
import DarkMode from './components/DarkMode';

function App() {
    const [Authed, setAuthed] = useState(false)
    const [User, setUser] = useState(null)
    const [Notifications, setNotifications] = useState([])
    const [colourTheme, setTheme] = DarkMode();
    const [friends, setFriends] = useState([])

    useEffect(() => {
        if(Cookies.get('token')){
            fetch('https://api.faynscloud.co.uk/api/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({token: Cookies.get('token')})
            }).then(x => x.json()).then(y => {
                setAuthed(y.authed)
                if(y.authed){
                    setUser(decode(Cookies.get('token')))
                    setFriends(decode(Cookies.get('token')).friends)
                } else {
                    setUser(null)
                }
            })

            // fetch notifications
        } else {
            return setAuthed(false)
        }
    }, [])

    return (
        <div class="flex flex-col h-screen justify-between bg-[#fdfffc] dark:bg-[#afafaf]">
            <div>
				<Router>
					<Navigation authed={Authed} user={User || null} />
					<Routes>
                        <Route path="/" exact element={<Home />} />
                        <Route path="/excercises" exact element={<Excercises />} />
						<Route path="/reactions" exact element={<ReactionTimer user={User} />} />
                        <Route path="/numbermemory" exact element={<NumberMemory user={User} />} />
                        <Route path="/sequence" exact element={<SequenceMemory user={User} />} />
                        <Route path='/login' exact element={<Login authed={Authed} />} />
                        <Route path='/register' exact element={<Register />} />
                        <Route path='/profile/:uuid' exact element={<Profile authed={Authed} user={User || null} friends={friends}/>} />
                        <Route path='/profile' exact element={<Profile authed={Authed} user={User || null}/>} />
                        <Route path='/stats' exact element={<Stats authed={Authed} user={User || null}/>} />
                        <Route path='/typing' exact element={<TypingSpeed />} />
                        <Route path='/chimptest' exact element={<ChimpTest />} />
                        <Route path='/memory' exact element={<Memory />} />

                        { /* 404 */ }
                        <Route path='*' element={<NotFound />}/>
					</Routes>
				</Router>
			</div>
            <Footer />
        </div>
    );
}

export default App;
