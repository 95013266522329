import React from 'react'
import { useState, useEffect, Redirect } from 'react'
import { save } from '../utils/function.js';
import SaveIcon from '@mui/icons-material/Save';

const SequenceMemory = (props) => {
    const [started, setStarted] = useState(false)
    const [ended, setEnded] = useState(false)
    const [ready, setReady] = useState(false)
    const [sequence, setSequence] = useState([])
    const [selectedSequence, setSelectedSequence] = useState([])
    const [score, setScore] = useState(0)

    const generateSequence = () => {
        let temp = sequence
        temp.push(Math.floor(Math.random() * (9 - 1 + 1)) + 1)
        setSequence(temp)
    }

    const runSequence = () => {
        setTimeout(() => {
            let i = 0
            let interval = setInterval(() => {
                if (i < sequence.length) {
                    document.getElementById(`grid-${sequence[i]}`).classList.remove("bg-white")
                    document.getElementById(`grid-${sequence[i]}`).classList.add("bg-[#00FF00]")
                    setTimeout(() => {
                        document.getElementById(`grid-${sequence[i-1]}`).classList.remove("bg-[#00FF00]")
                        document.getElementById(`grid-${sequence[i-1]}`).classList.add("bg-white")
                        if(i === sequence.length){
                            clearInterval(interval)
                            setReady(true)
                        }
                    }, 500)
                    i++
                }
            }, 1000)  
        }, 100)
    }

    const handleClick = (e) => {
        if(!ready) {
            return
        }
        let temp = selectedSequence
        temp.push(parseInt(e.target.id.slice(-1)))
        console.log(temp)
        console.log(sequence)
        if(temp.length == sequence.length) {
            if(temp.toString() == sequence.toString()) {
                setScore(score + 1)
                generateSequence()
                setSelectedSequence([])
                setReady(false)
                runSequence()
            } else {
                setSequence([])
                setSelectedSequence([])
                setStarted(false)
                setEnded(true)
                setReady(false)
            }
        } else {
            if(sequence.slice(0, temp.length).toString() !== temp.toString()) {
                setSequence([])
                setSelectedSequence([])
                setStarted(false)
                setEnded(true)
                setReady(false)
            }
        }
    }

    return (
        <div class="flex justify-center py-10">
            <div class='bg-slate-100 w-96 shadow-xl rounded px-5 py-3'>
                <h1 class='text-2xl font-bold text-center pb-1'>Sequence Memory</h1>
                <div class="flex justify-center">
                    {
                        !started ? (
                            !ended ? (
                                <button type="button" onClick={(e) => {setStarted(true); generateSequence(); runSequence()} } class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Ready!</button>
                            ) : (
                                <div>
                                    <button type="button" onClick={(e) => {setStarted(true); generateSequence(); runSequence(); setScore(0)} } class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Play Again</button>
                                    <div class='flex flex-row'>
                                        <h1 class='text-xl font-bold text-center pb-1'>Score: {score}</h1>
                                        <div class='pl-1'>
                                            <SaveIcon id='save' onClick={(e) => save('sequenceMemory', props.user, Date.now(), score)}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : ( 
                            <div class='relative z-0'>
                                {
                                    ready ? (
                                        <></>
                                    ) : (
                                        <div class="absolute inset-0 flex justify-center items-center z-10"></div>
                                    )
                                }
                                <div class="flex justify-center py-10">
                                    <div class="grid grid-rows-3 gap-3">
                                        <div class="grid grid-cols-3 gap-3">
                                            <a id="grid-clickable-1" onClick={(e) => handleClick(e)}>
                                                <div id="grid-1" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-2" onClick={(e) => handleClick(e)}>
                                                <div id="grid-2" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-3" onClick={(e) => handleClick(e)}>
                                                <div id="grid-3" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                        </div>
                                        <div class="grid grid-cols-3 gap-3">
                                            <a id="grid-clickable-4" onClick={(e) => handleClick(e)}>
                                                <div id="grid-4" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-5" onClick={(e) => handleClick(e)}>
                                                <div id="grid-5" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-6" onClick={(e) => handleClick(e)}>
                                                <div id="grid-6" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                        </div>
                                        <div class="grid grid-cols-3 gap-3">
                                            <a id="grid-clickable-7" onClick={(e) => handleClick(e)}>
                                                <div id="grid-7" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-8" onClick={(e) => handleClick(e)}>
                                                <div id="grid-8" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                            <a id="grid-clickable-9" onClick={(e) => handleClick(e)}>
                                                <div id="grid-9" class="bg-white shadow-xl h-16 w-16 rounded p-4 active:bg-slate-400"/>
                                            </a>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div>Score: {score}</div>
                                            <div class='flex flex-row'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SequenceMemory