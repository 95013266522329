import React from 'react'
import { save } from '../utils/function.js'
import SaveIcon from '@mui/icons-material/Save'

const NumberMemory = (props) => {
    const [number, setNumber] = React.useState(Math.random().toString().slice(2,3))
    const [score, setScore] = React.useState(0)
    const [input, setInput] = React.useState('')
    const [started, setStarted] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const [ready, setReady] = React.useState(false)
    const [showNumber, setShowNumber] = React.useState(false)
    const [progress, setProgress] = React.useState(0)

    const generateNumber = (score) => {
        setNumber(Math.random().toString().slice(2,score+3));
    }

    const playRound = () => {
        setShowNumber(true);

        setTimeout(() => {
            setShowNumber(false);
            setReady(false);
        }, (number.length*1000)+1000);
        
    }

    return (
        <div>
            <div class='flex justify-center pt-5 px-2'>
                <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                    <h1 class='text-2xl font-bold text-center pb-2'>Number Memory</h1>
                    <div class=''>
                        {!started ? (
                            <div>
                                <button type="button" onClick={(e) => {setStarted(true); playRound(); setFailed(false); setScore(0); }} class="text-white w-full bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2">Start!</button>
                                {
                                    failed ? (
                                        <div class='flex justify-center flex-row'>
                                            <h1 class='text-xl font-bold text-center pb-1'>Score: {score}</h1>
                                            <div class='relative pl-1'>
                                                <SaveIcon id='save' onClick={(e) => {save('numberMemory', props.user, Date.now(), score); }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        ) : (
                            <div>
                                <div class='flex justify-center'> 
                                    {
                                        showNumber ? (
                                            <div>
                                                <div class='select-none text-4xl font-bold text-center pb-1'>
                                                    {number}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {
                                                    ready ? (
                                                        <div class=''>
                                                            <button type="submit" onClick={(e) => playRound()} class="text-white w-full bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2">Ready!</button>
                                                            <h1 class='text-xl font-bold text-center pb-1'>Score: {score}</h1>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <form onSubmit={() => {
                                                                if (input == number) {
                                                                    setScore(score + 1)
                                                                    generateNumber(score + 1)
                                                                    setReady(true)
                                                                } else {
                                                                    generateNumber(0)
                                                                    setStarted(false)
                                                                    setFailed(true)
                                                                }
                                                                setInput('')
                                                            }}>
                                                                <input type='text' value={input} onChange={(e) => setInput(e.target.value)} class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 text-center"></input>
                                                            </form>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                            
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NumberMemory