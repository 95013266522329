import * as React from 'react';
import { useNavigate, Link } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Cookies from 'js-cookie';
import DarkMode from './DarkMode';
import Switcher from './ThemeToggle';

const pages = [{ name: 'Home', link: '/' }, { name: 'Excercises', link: '/excercises' }, { name: 'Stats', link: '/stats' }, { name: 'Profile', link: '/profile'}];

const Navbar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElNotifications, setAnchorElNotifications] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [notifications, setNotifications] = React.useState([]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenNotificationsMenu = (event) => {
        setAnchorElNotifications(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseNotificationsMenu = () => {
        setAnchorElNotifications(null);
    };

    const logout = () => {
        Cookies.remove('token');
        window.location.href = '/';
    };

    return (
        <AppBar disableAnimation={true} position="static" class="bg-blue-500 dark:bg-slate-800 dark:text-slate-200">
            <Container maxWidth="xl">
                <Toolbar disableGutters disableAnimation={true}>
                    <Link to={'/'}>
                        <div class='flex flex-row pl-2'>
                            <div class='py-1'>
                                <FitnessCenterIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
                            </div>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Mental Gym
                            </Typography>
                        </div>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Link to={page.link} key={page.name}>
                                    <Button color="inherit">{page.name}</Button>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    <Link to={'/'}>
                        <div class='flex flex-row'>
                            <div class='py-1'>
                                <FitnessCenterIcon sx={{ display: { xs: 'none', md: 'none' }, mr: 1 }} />
                            </div>
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Mental Gym
                            </Typography>
                        </div>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Link to={page.link} key={page.name}>
                                <Button class='space-x-1 p-2 font-mono font-semibold text-lg'>{page.name}</Button>
                            </Link>
                        ))}
                    </Box>
                    {/* centre veritcally in bar */}
                    <div class="px-3">
                        <Switcher class='px-2 items-center'/>
                    </div>
                    {
                        props.user !== null ? (
                            <Box sx={{ flexGrow: 0 }} class='pr-2'>
                                <IconButton onClick={handleOpenNotificationsMenu} sx={{ p: 0 }} class='pr-3'>
                                    <NotificationsNoneIcon />
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElNotifications}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElNotifications)}
                                    onClose={handleCloseNotificationsMenu}
                                >
                                    {
                                        notifications.length !== 0 ? (
                                            notifications.map((notification) => {
                                                return (
                                                    <MenuItem key={notification.id} onClick={handleCloseNotificationsMenu}>
                                                        <Typography textAlign="center">{notification.message}</Typography>
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <div class='px-2'>
                                                <Typography textAlign="center">No notifications</Typography>
                                            </div>
                                        )
                                    }
                                </Menu>

                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <div class="w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                            <span class="font-semibold text-gray-600 dark:text-gray-300">{props.user.username.charAt(0)}</span>
                                        </div>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <Link to={'/profile'}>
                                        <MenuItem key={'profile'} onClick={handleCloseUserMenu}>
                                            <Typography textAlign="center">Profile</Typography>
                                        </MenuItem>
                                    </Link>
                                    <MenuItem key={'logout'} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center" onClick={() => logout()}>Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <div class='pt-2 content-center'>
                                <Box sx={{ flexGrow: 0  }}>
                                    <Link to={'/login'}>
                                        <Button class='font-poppins select-none text-white bg-green-700 hover:bg-green-800 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2'>LOGIN</Button>
                                    </Link>
                                </Box>
                            </div>
                        )
                    }
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar