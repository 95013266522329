import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div class='flex justify-center pt-5 px-2'>
            <div class='grid grid-cols-3 mx-5 gap-2'>
                <Link to='/stats'>
                    <div>
                        <div class='bg-white w-auto shadow-xl rounded px-5 py-3'>
                            <h1 class='text-center'>Stats</h1>
                        </div>
                    </div>
                </Link>
                <Link to='/excercises'>
                    <div>
                        <div class='bg-white w-auto shadow-xl rounded px-5 py-3'>
                            <h1 class='text-center'>Excercises</h1>
                        </div>
                    </div>
                </Link>
                <Link to='/profile'>
                    <div>
                        <div class='bg-white w-auto shadow-xl rounded px-5 py-3'>
                            <h1 class='text-center'>Profile</h1>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Home