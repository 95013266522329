import React from 'react'
import { useState, useEffect } from 'react'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const levels = {
    1: 3,
    2: 3,
    3: 3,
    4: 4,
    5: 4,
    6: 4,
    7: 5,
    8: 5,
    9: 5,
    10: 6,
}

const Memory = () => {
    const [round, setRound] = useState(1)
    const [selectedOne, setSelectedOne] = useState()
    const [selectedTwo, setSelectedTwo] = useState()
    const [started, setStarted] = useState(false)
    const [finished, setFinished] = useState(false)
    const [inplay, setInplay] = useState(false)
    const [grid, setGrid] = useState()

    useEffect(() => {
        createGrid()
    }, [round])

    const cards = [
        {
            id: 1,
            image: <FitnessCenterIcon />,
        }
    ]

    const createGrid = () => {
        let temp = []
        for(let i = 0; i < 9; i++) {
            temp.push(Math.floor(Math.random() * (cards.length - 1 + 1)) + 1)
        }
        setInplay(temp)

        // create grid based on round number in levels
        let size = levels[round]
        let grid = []

        for (let i = 0; i < size; i++) {
            let row = []
            for (let j = 0; j < size; j++) {
                row.push(<a id={i} class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-16 h-16' onClick={handleClick}>{cards[0].image}</a>)
            }
            grid.push(<div class='flex justify-center'>{row}</div>)
        }

        setGrid(grid)
        console.log(grid)
    }

    const handleClick = (e) => {
        if(selectedOne) {
            setSelectedTwo(e.target.id)
        } else {
            setSelectedOne(e.target.id)
        }

        if(selectedOne && selectedTwo) {
            if(selectedOne === selectedTwo) {
                setRound(round + 1)
                setSelectedOne()
                setSelectedTwo()
            } else {
                setFinished(true)
            }
        }
    }

    return (
        <div class="flex justify-center py-10">
            <div class='bg-slate-100 w-96 shadow-xl rounded px-5 py-3'>
                <h1 class='text-2xl font-bold text-center pb-1'>Image Memory</h1>
                <div class="flex justify-center">
                    {grid}
                </div>
            </div>
        </div>
    )
}

export default Memory