import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom'

async function loginUser(credentials) {
    return fetch("https://api.faynscloud.co.uk/api/signin", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json())
}


const Login = (props) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [inputValue, setInputValue] = useState("");
    const [msg, setMsg] = useState()

    if(props.authed === true){
        return window.location.href=`/`
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const res = await loginUser({
            email: email.toLowerCase(),
            password: password
        })
        if(res.token === undefined){
            setMsg("Incorrect username or password")
        }
        if(res.token){
            Cookies.set('token', res.token)
            return window.location.href=`/`
        }
    }

    const resetInputField = () => {
        setInputValue("");
    };

    return (
        <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div class="bg-white w-96 shadow-xl rounded p-5">
                <div className="max-w-md w-full space-y-8">
                    <h2 className="mt-6 text-center text-3xl text-gray-900 font-bold">Sign in</h2>
                    <form className="mt-8 space-y-2" onSubmit={(e) => handleSubmit(e)}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email address"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div class=' text-red-600 text-center'>
                                {msg}
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                                Sign in
                            </button>
                            <p class='text-sm'>
                                <Link to={'/register'}>
                                    <a className="text-gray-500 pt-1">
                                        Don't have an account? Sign up
                                    </a>
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
