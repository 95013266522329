import React from 'react'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import GameStats from './GameStats.jsx'
import Cookies from 'js-cookie'
import decode from 'jwt-decode'

const Stats = (props) => {
    const [selected, setSelected] = useState(0)

    if(!Cookies.get('token')) window.location.href = '/login'

    const navigate = useNavigate();
    let { uuid } = useParams();
    const userId = (decode(Cookies.get('token'))).uuid

    if(props.authed === false){
        navigate('/login')
    }

    if(!uuid){
        let token = Cookies.get('token')
        if(!token){
            window.location.href = '/login'
        }
        uuid = userId
    }

    const profileTabClick = (e) => {
        if(selected) {
            document.getElementById(selected).classList = "flex inline-block select-none whitespace-nowrap rounded-t-md w-full p-2 bg-white hover:text-gray-700 hover:bg-gray-50"
        }
        document.getElementById(e.id).classList = 'flex inline-block select-none whitespace-nowrap rounded-t-md w-full p-2 text-gray-900 bg-gray-100'
        setSelected(e.id)

    }

    return (
        <div class='flex justify-center py-10'>
            <div class='bg-white w-auto shadow-xl rounded px-5 py-3'>
                <div>
                    <ul class="flex text-sm font-medium w-full text-center text-gray-500 divide-x divide-gray-200 rounded-lg rounded-t-md sm:text-sm">
                        <li class="w-full">
                            <a id='reactionTime' onClick={(e) => profileTabClick(e.target)} class="flex select-none whitespace-nowrap w-full p-2 rounded-t-md bg-white hover:text-gray-700 hover:bg-gray-50" aria-current="page">Reaction Timer</a>
                        </li>
                        <li class="w-full">
                            <a id='numberMemory' onClick={(e) => profileTabClick(e.target)} class="flex select-none whitespace-nowrap w-full p-2 rounded-t-md bg-white hover:text-gray-700 hover:bg-gray-50">Number Memory</a>
                        </li>
                        <li class="w-full">
                            <a id='sequenceMemory' onClick={(e) => profileTabClick(e.target)} class="flex select-none whitespace-nowrap w-full p-2 rounded-t-md bg-white hover:text-gray-700 hover:bg-gray-50">Sequence Memory</a>
                        </li>
                    </ul>
                </div>
                <div>
                    {
                        selected ? <GameStats game={selected} uuid={uuid} /> : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default Stats