import React from 'react'
import { useState, useEffect, useRef, useMemo } from 'react'


const levels = {
    1: 4,
    2: 4,
    3: 5,
    4: 5,
    5: 6,
    6: 6,
    7: 7,
    8: 7,
    9: 8,
    10: 8,
}


const generateSequence = (round) => {
    let temp = []
    for (let i = 0; i < round+2; i++) {
        // if id not in temp, add it
        let obj = {
            order: i + 1,
            id: Math.floor(Math.random() * (levels[round] * levels[round] - 1 + 1)) + 1,
        }

        if (!temp.some(item => item.id === obj.id)) {
            temp.push(obj)
        } else {
            i--
        }
    }

    return temp
}

export const ChimpTest = () => {
    const [started, setStarted] = useState(false)
    const [ended, setEnded] = useState(false)
    const [selected, setSelected] = useState([])
    const [round, setRound] = useState(1)
    const [sequence, setSequence] = useState()
    const [ready, setReady] = useState(false)

    const playRound = (round) => {
        let size = levels[round]
        let grid = []

        let sequence = generateSequence(round)
    
        for (let i = 0; i < size; i++) {
            let row = []
            for (let j = 0; j < size; j++) {
                if(sequence.some(item => item.id === (i*size + j + 1))) {
                    row.push(<a id={i*size+j} class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-16 h-16' onClick={() => {
                        handleClick(i*size+j)
                    }}>{
                        sequence.find(item => item.id === (i*size + j + 1)).order
                    }</a>)
                } else {
                    row.push(<div class='bg-white text-white font-bold py-2 px-4 rounded w-16 h-16'></div>)
                }
                    
            }
            grid.push(<div class='flex justify-center'>{row}</div>)
        }
        return grid
    }

    useEffect(() => {
        console.log('useEffect')
    }, [started])

    const onReady = () => {
        // remove all text from the squares
        console.log(sequence)
        setReady(true)
    }

    const handleClick = (i) => {
        if (ready) {
            let temp = selected
            temp.push(i)
            setSelected(temp)
        }

        console.log(i)
        console.log(selected)
        console.log(sequence)
        console.log(selected[selected.length-1])
        console.log(sequence[selected.length-1].id)

        // check after each click if its correct
        if(selected[selected.length-1] !== sequence[selected.length-1].id) {
            setEnded(true)
            setReady(false)
            setRound(1)
            setSelected([])
        } else if (selected.length === sequence.length) {
            setRound(round+1)
            setReady(false)
            setSelected([])
        }
    }


    return (
        <div class='flex justify-center py-10'>
            {
                !started ? (
                    <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                        <h1 class='text-2xl font-bold text-center pb-1'>Chimp Test</h1>
                        <p class='text-center pb-1'>The Chimp Test is a memory game where you have to remember the order of the numbers that appear on the screen.</p>
                        <p class='text-center pb-1'>The game starts with 4x4 grid and the numbers will appear in a random order. You have to remember the order of the numbers and click on them in the same order.</p>
                        <p class='text-center pb-1'>The grid will increase in size as you progress through the levels.</p>
                        <button class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full' onClick={() => {
                            setStarted(true);
                        }}>Start</button>
                    </div>
                ) : (
                    <div>
                        {
                            !ended ? (
                                <div>
                                    <div class='bg-white shadow-xl rounded py-3'>
                                        {
                                            setSequence(generateSequence(round))
                                        }
                                        {
                                            playRound(round)
                                        }
                                    </div>
                                    {
                                        !ready ? <button class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-auto' onClick={() => {onReady()}}>Ready</button> : <></>
                                    }
                                </div> 
                            ) : (
                                <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                                    <h1 class='text-2xl font-bold text-center pb-1'>Game Over</h1>
                                    <p class='text-center pb-1'>You have completed the Chimp Test.</p>
                                    <p class='text-center pb-1'>Your score is {round-1}.</p>
                                    <button class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full' onClick={() => {
                                        setStarted(false);
                                        setEnded(false);
                                        setSelected([]);
                                        setRound(1);
                                        setSequence();
                                    }
                                    }>Play Again</button>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}
