import React from 'react'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import decode from 'jwt-decode'
import { addFriend } from '../utils/function.js'
import GameStats from '../components/GameStats.jsx'
import Loading from '../components/Loading.jsx'

const Profile = (props) => {
    const [profileData, setProfileData] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    if(!Cookies.get('token')) window.location.href = '/login'

    const navigate = useNavigate();
    let { uuid } = useParams();
    const userId = (decode(Cookies.get('token'))).uuid

    if(props.authed === false){
        navigate('/login')
    }

    if(!uuid){
        let token = Cookies.get('token')
        if(!token){
            window.location.href = '/login'
        }
        uuid = userId
    }

    useEffect(() => {
        fetch(`https://api.faynscloud.co.uk/data/profile/${uuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authentication': 'Bearer ' + Cookies.get('token')
            }
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setProfileData(data.data)
            setIsLoaded(true)
        })

        return () => {
            setProfileData()
            setIsLoaded(false)
        }

    }, [uuid])

    if(!isLoaded){
        return <Loading/>
    }

    return (
        <div class='flex justify-center py-10'>
			<div class='bg-white w-auto shadow-xl rounded px-5 py-3'>
                <div class='relative flex flex-row py-1'>
                    <h1 class='text-2xl font-bold text-left pb-1'>{profileData.displayName}</h1>
                    <div>
                        {
                            uuid === userId ? <button class='absolute right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full'>Edit Profile</button> : (
                                props.friends.some(e => e.uuid === uuid) ? <button class='absolute right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full'>Remove Friend</button> : <button class='absolute right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full' onClick={() => addFriend(userId, uuid)}>Add Friend</button>
                            )

                        }
                    </div>
                </div>
                <div class='flex'>
                    <p class='text-left'>UUID: {profileData.uuid}</p>                    
                </div>
                <div>
                    Friends: {profileData.friends.length}
                </div>
                <div>
                    Badges
                </div>
            </div>
        </div>
    )
}

export default Profile