import React from 'react'
import { Link } from 'react-router-dom'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <footer class="shadow bg-gray-500 dark:bg-gray-800 dark:text-gray-400">
            <div class="w-full mx-auto container md:p-2 px-4 py-2 md:flex md:items-center md:justify-between">
                    <div class='flex flex-row'>
                        <div class='py-1'>
                            <FitnessCenterIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
                        </div>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Mental Gym
                        </Typography>
                    </div>
                <ul class="flex flex-wrap items-center mt-2 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    {/* <li>
                        <a href="/" class="mr-4 hover:underline md:mr-6 ">About</a>
                    </li>
                    <li>
                        <a href="/" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="/" class="mr-4 hover:underline md:mr-6">Licensing</a>
                    </li>
                    <li>
                        <a href="/" class="hover:underline">Contact</a>
                    </li> */}
                </ul>
            </div>
        </footer>
    )
}

export default Footer