import Cookies from 'js-cookie'
const Swal = require('sweetalert2')

async function save(game, userProp, timestamp, score) {
    let timerInterval

    // if no uuid, return popup saying they must login to save results
    if(userProp === null) {
        Swal.fire({
            title: 'You must login to save your results!',
            icon: 'error',
            showDenyButton: true,
            confirmButtonText: 'Login',
            denyButtonText: `Register`,
            confirmButtonColor: '#32CD32',
            denyButtonColor: '#32CD32',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                return window.location.href = "/login"
            } else if (result.isDenied) {
                return window.location.href = "/register"
            }
        })
    }

    let uuid = userProp.uuid

    return fetch("https://api.faynscloud.co.uk/data/save", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authentication': 'Bearer ' + Cookies.get('token')
        },
        body: JSON.stringify({
            game: game,
            uuid: uuid,
            timestamp: timestamp,
            score: score
        })
    }).then(res => {
        if (res.status === 200) {
            Swal.fire({
                title: 'Saved!',
                icon: 'success',
                toast: true,
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
                position: 'top-end',
            })
            return document.getElementById('save').classList.add('pointer-events-none');
        }
        else {
            return false
        }
    })
}

async function addFriend(user, target) {
    return fetch("https://api.faynscloud.co.uk/data/addfriend", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: user,
            target: target
        })
    }).then(res => {
        if (res.status === 200) {
            Swal.fire({
                title: 'Added!',
                icon: 'success',
                toast: true,
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
                position: 'top-end',
            })
            return document.getElementById('addfriend').classList.add('pointer-events-none');
        }
        else {
            return false
        }
    })
}

export { save, addFriend }