import React from 'react'
import { useState, useEffect, Link, Fragment } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { save } from '../utils/function.js';

const ReactionTimer = (props) => {
    const [time, setTime] = useState(0)
    const [ready, setReady] = useState(false)
    const [timeArray, setTimeArray] = useState([])
    const [isRunning, setIsRunning] = useState(false)
    const [timer, setTimer] = useState()

    function reactionClick() {
        if(ready){
            setReady(false)
            return timeArray.push((Date.now() - time))
        }
        if (!isRunning) {
            setIsRunning(true)
            document.getElementById('clickable-area').style.backgroundColor = 'red'
            setTimer(setTimeout(() => {
                document.getElementById('clickable-area').style.backgroundColor = 'lime'
                setIsRunning(false)
                setReady(true)
                setTime(Date.now())
            }, (Math.random() * (6000 - 2000) + 2000)))
        }
        if (isRunning) {
            setIsRunning(false)
            document.getElementById('clickable-area').style.backgroundColor = 'lime'
            clearTimeout(timer)
            timeArray.push(1000)
        }
    }

    return (
        <div>
            <div class=''>
                {
                    timeArray.length !== 5 ? (
                        <a onPointerDown={(e) => reactionClick()}>
                            <div id='clickable-area' class='flex justify-center py-2 h-52 shadow-sm rounded bg-[#00FF00]'>
                                
                            </div>
                        </a>
                    ) : ( 
                        <div id='clickable-area' class='flex justify-center py-2 h-52 shadow-sm rounded bg-gray-400'>
                            
                        </div> 
                    )
                        
                }
            </div>
        
            <div class='flex justify-center py-10'>
                <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                    <h1 class='text-2xl font-bold text-center pb-1'>Reaction Timer</h1>
                    <div class=''>
                        <div class='grid grid-cols-2'>
                            {/* <SaveIcon onClick={(e) => save('reactionTime', props.user, Date.now(), Math.round(timeArray.reduce((a, b) => a + b, 0) / timeArray.length))}/> */}
                            <div>
                                <div class='flex'>
                                    <h2 class='text-lg font-semibold'>
                                        Round: {timeArray.length}/5
                                    </h2>
                                    <div class='justify-end'>
                                        <RefreshIcon onClick={(e) => {
                                            setTimeArray([])
                                        }}/>
                                    </div>
                                </div>
                                <div>
                                    {
                                        timeArray.map((time, index) => {
                                            return (
                                                <Fragment>
                                                    <p>{index + 1}: {time}ms</p>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    timeArray.length === 5 ? (
                                        <div class='grid h-full place-items-center'>
                                            <SaveIcon id='save' onClick={(e) => { save('reactionTime', props.user, Date.now(), Math.round(timeArray.reduce((a, b) => a + b, 0) / timeArray.length)) } }/>
                                        </div>
                                    ) : (
                                        timeArray.length === 0 ? (
                                            <div class='grid h-full place-items-center'>
                                                <p></p>
                                            </div>
                                        ) : (
                                            <div class='grid h-full place-items-center'>
                                                <></>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactionTimer