import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

async function registerUser(email, displayName, password) {
    return fetch("https://api.faynscloud.co.uk/api/register", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            displayName: displayName,
            password: password
        })
    })
        .then(res => res.json())
}

const Register = () => {
    const [email, setEmail] = useState();
    const [displayName, setDisplayName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [inputValue, setInputValue] = useState("");
    const [msg, setMsg] = useState()

    const handleSubmit = async (e) => {      
        e.preventDefault()
        if(password !== confirmPassword){
            setMsg("Passwords do not match")
            return
        }

        // validate email
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!emailRegex.test(email)){
            setMsg("Invalid email")
            return
        }
        const res = await registerUser(email, displayName, password)
        if(res.token){
            Cookies.set('token', res.token)
            return window.location.href=`/`
        }
    }

    return (
        <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div class="bg-white w-96 shadow-xl rounded p-5">
                <div className="max-w-md w-full space-y-8">
                    <h2 className="mt-6 text-center text-3xl text-gray-900 font-bold">Register</h2>
                    <div>
                        <form class="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                <input 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    onChange={e => setEmail(e.target.value)}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="name@mentalgym.com" required=""/>
                            </div>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Display Name</label>
                                <input 
                                    type="username" 
                                    name="username" 
                                    id="username" 
                                    onChange={e => setDisplayName(e.target.value)}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="Display Name" required=""/>
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    placeholder="••••••••" 
                                    onChange={e => setPassword(e.target.value)}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                            </div>
                            <div>
                                <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 ">Confirm password</label>
                                <input 
                                    type="password" 
                                    name="confirm-password" 
                                    id="confirm-password" 
                                    placeholder="••••••••" 
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " required=""/>
                            </div>
                            {<p className="text-red-500">{msg}</p>}
                            {/* <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 " required=""/>
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="terms" class="font-light text-gray-500 ">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                                </div>
                            </div> */}
                            <div>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                                    Register
                                </button>
                                <p class="text-sm font-light text-gray-500 pt-1">
                                    Already have an account? <Link to={'/login'}><a class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</a></Link>
                                </p>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Register