import React from 'react'
import {Link} from 'react-router-dom'

const Excercises = () => {
    return (
        <div class='flex justify-center pt-5'>
            <div class='grid grid-rows-5 gap-2'>
                <Link to={"/reactions"}>
                    <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                        Reaction timer
                    </div>
                </Link>

                <Link to={"/numbermemory"}>
                    <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                        Number Memorisation
                    </div>
                </Link>

                <Link to={"/sequence"}>
                    <div class='bg-white w-96 shadow-xl rounded px-5 py-3'>
                        Sequence Memorisation
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Excercises